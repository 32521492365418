import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Layout, Row, Upload, message } from "antd";
import { useEffect, useState } from "react";
import "./App.css";
import { sendImageApis } from "./api/faceApi";
const { Panel } = Collapse;
const { Header, Content } = Layout;

function App() {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [data, setData] = useState<any[] | null>(null);

  const handleSendImg = async () => {
    if (selectedImage !== null) {
      await sendImageApis(selectedImage).subscribe((res) => {
        console.log(res);
        setData(res);
      });
    }
  };

  const handleUpload = (file: File) => {
    setSelectedImage(file);
    message.success(`${file.name} file selected successfully`);
    return false; // Prevent automatic upload
  };

  useEffect(() => {
    setData(null);
  }, [selectedImage]);

  return (
    <Layout className="layout">
      <Header className="header">
        <div style={{fontSize: 20, fontWeight: 'bold'}}>Face Analysis</div>
      </Header>
      <Content className="content" >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} className="left">
            <div className="left-column">
            <div style={{ fontSize: 16, paddingBottom: 20 }}>
              Ứng dụng Demo <strong>Nhận dạng Khuôn Mặt, Giới Tính và Độ Tuổi</strong> sử dụng công nghệ học máy
              để phân tích hình ảnh khuôn mặt. Khi người dùng tải lên ảnh, hệ thống tự động nhận
              diện khuôn mặt và dự đoán giới tính, độ tuổi. Ứng dụng sử dụng các mô hình học sâu
              như <strong>Convolutional Neural Networks (CNN)</strong> để đảm bảo độ chính xác cao. API của ứng 
              dụng có thể <strong>tích hợp dễ dàng với các ứng dụng Web, Mobile, hay Desktop</strong>.
            </div>
              <Upload
                accept="image/*"
                beforeUpload={handleUpload} // Handle file selection
                showUploadList={false} // Hide the default file list
              >
                <Button icon={<UploadOutlined />}>Select Image</Button>
              </Upload>
              {selectedImage && (
                <div style={{ alignItems: "center" }}>
                  <img
                    alt="not found"
                    src={URL.createObjectURL(selectedImage)}
                    style={{ maxWidth: "100%" }} // Ensure the image fits within the container
                  />
                  <br />
                  <Button onClick={() => setSelectedImage(null)}>
                    Remove Image
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={handleSendImg}
                  >
                    Process
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} md={12} className="Right">
            {data && (
              <div className="right-column">
                <h3>Kết quả phân tích: </h3>
                <Collapse defaultActiveKey={["1"]}>
                  {data.map((item, index) => (
                    <Panel header={`Person ${index + 1}`} key={index + 1}>
                      <Collapse defaultActiveKey={["1", "2"]}>
                        <Panel header="Age Range" key="1">
                          <p>High: {item.ageRange.high}</p>
                          <p>Low: {item.ageRange.low}</p>
                        </Panel>
                        <Panel header="Gender" key="2">
                          <p>Confidence: {item.gender.confidence}%</p>
                          <p>Value: {item.gender.value.value}</p>
                        </Panel>
                        <Panel header="Emotions" key="3">
                          <Collapse defaultActiveKey={["1"]}>
                            {item.emotions.map((emotion: any, emotionIndex: number) => (
                              <Panel header={emotion.type.value} key={emotionIndex + 1}>
                                <p>Confidence: {emotion.confidence}%</p>
                              </Panel>
                            ))}
                          </Collapse>
                        </Panel>
                      </Collapse>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default App;
