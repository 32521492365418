import { catchError, map, Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import HttpClient from "./http-client";

const api = 'https://recognition.tingconnect.com/api/Face/detect/file';

export const sendImageApis = (
  Photo: File
): Observable<any> => {
  const formData = new FormData();
  formData.append('Photo', Photo);
  console.log(formData);

  return HttpClient.upload(`${api}`, formData).pipe(
    map((res) => (res as any) || null),
    catchError((e: AjaxError) => throwError(e))
  );
};